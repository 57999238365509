import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../src/components/Layout/Layout';
import Heimdall from '../src/components/Heimdall/Heimdall';
import Hellheim from '../src/components/Hellheim/Hellheim';
import NeoAesir from '../src/components/NeoAesir/NeoAesir';
import Forseti from '../src/components/Forseti/Forseti';
import Hod from '../src/components/Hod/Hod';
import SEO from '../src/helpers/seo';
import tt from '../src/helpers/translation';
import './preguntas_frecuentes.scss';
import urlResolver from '../src/helpers/urlResolver';

export const pageQuery = ({ data, pathContext: { locale } }) => graphql`
  query FQ($name: String!, $locale: String!) {
    allFqImageHeaderMobile(filter: { tag: { eq: "" }, lang: { eq: $locale } }) {
      edges {
        node {
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    allFqSeoData(filter: { tag: { eq: $name }, lang: { eq: $locale } }) {
      edges {
        node {
          _0 {
            title
          }

          _1 {
            description
          }
        }
      }
    }
    allFqBreadCrumbBlock(filter: { tag: { eq: $name }, lang: { eq: $locale } }) {
      edges {
        node {
          name
        }
      }
    }
    allFqHeaderTitleBlock(filter: { tag: { eq: $name }, lang: { eq: $locale } }) {
      edges {
        node {
          title
          description
        }
      }
    }
    allFqImageHeaderBlock(filter: { tag: { eq: $name }, lang: { eq: $locale } }) {
      edges {
        node {
          image
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    allFqPreguntasFrecuentesTitle(filter: { tag: { eq: $name }, lang: { eq: $locale } }) {
      edges {
        node {
          title
        }
      }
    }
    allFqPreguntasFrecuentes(
      filter: { tag: { eq: $name }, lang: { eq: $locale } }
      sort: { fields: position, order: ASC }
    ) {
      edges {
        node {
          question
          answer
          faq_type
        }
      }
    }
    pasesFAQ: allFqPreguntasFrecuentes(
      filter: { lang: { eq: $locale }, faq_type: { eq: "pases" } }
      sort: { fields: position, order: ASC }
    ) {
      edges {
        node {
          question
          answer
          faq_type
        }
      }
    }
    areaPrivadaFAQ: allFqPreguntasFrecuentes(
      filter: { lang: { eq: $locale }, faq_type: { eq: "areaPrivada" } }
      sort: { fields: position, order: ASC }
    ) {
      edges {
        node {
          question
          answer
          faq_type
        }
      }
    }
    pagoFAQ: allFqPreguntasFrecuentes(
      filter: { lang: { eq: $locale }, faq_type: { eq: "pago" } }
      sort: { fields: position, order: ASC }
    ) {
      edges {
        node {
          question
          answer
          faq_type
        }
      }
    }
    klarnaFAQ: allFqPreguntasFrecuentes(
      filter: { lang: { eq: $locale }, faq_type: { eq: "klarna" } }
      sort: { fields: position, order: ASC }
    ) {
      edges {
        node {
          question
          answer
          faq_type
        }
      }
    }
    allFqRichData(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          rich_markup {
            og_title
            rich_markup_type
            og_description
          }
        }
      }
    }
  }
`;

class PreguntasFrecuentes extends React.Component {
  state = {};

  render() {
    const { pageContext } = this.props;
    const heimdallData = {
      name: this.props.data.allFqHeaderTitleBlock.edges[0].node.title,
      image: {
        url: this.props.data.allFqImageHeaderBlock.edges[0].node.localImage.childImageSharp.fluid,
        imageMobile:
          this.props.data.allFqImageHeaderMobile.edges[0].node.localImage.childImageSharp.fluid,
      },
    };

    const hellheimData = {
      title: this.props.data.allFqPreguntasFrecuentesTitle.edges[0].node.title,
      text: '',
      menus: this.props.data.allFqPreguntasFrecuentes.edges.map((item, index) => ({
        title: item.node.question,
        text: item.node.answer,
      })),
      titleType: 'h3',
    };

    const prepareForsetiData = () => {
      const annualPasses = {
        es: 'pases-anuales',
        ca: 'passis-anuals',
        fr: 'pass-annuels',
        en: 'annual-passes',
        ru: 'annual-passes',
      };
      const listHotels = [
        {
          name: '/preguntas-frecuentes',
          displayName: tt('PortAventura Park', this.props.pageContext.locale),
          page: '',
        },
        {
          name: '/preguntas-frecuentes/aquatic-park',
          displayName: tt('PortAventura Caribe Aquatic Park', this.props.pageContext.locale),
          page: 'aquatic-park',
        },
        {
          name: '/preguntas-frecuentes/pase-express',
          displayName: tt('Pase Express', this.props.pageContext.locale),
          page: 'pase-express',
        },
        {
          name: '/preguntas-frecuentes/hoteles-de-portaventura',
          displayName: tt('Hoteles de PortAventura World', this.props.pageContext.locale),
          page: 'hoteles-de-portaventura',
        },
        {
          name: `/preguntas-frecuentes/${annualPasses[this.props.pageContext.locale]}`,
          displayName: tt('Pases Anuales', this.props.pageContext.locale),
          page: 'pases-anuales',
        },
        {
          name: '/preguntas-frecuentes/oferta-de-restauracion',
          displayName: tt('Oferta de restauración', this.props.pageContext.locale),
          page: 'oferta-de-restauracion',
        },
        {
          name: '/preguntas-frecuentes/entradas-a-los-parques',
          displayName: tt('Entradas a los parques', this.props.pageContext.locale),
          page: 'entradas-a-los-parques',
        },
      ];
      const hotelTabs = listHotels
        .map((item, i) => ({
          name: item.displayName.toUpperCase(),
          link: urlResolver.resolve(pageContext.locale, 'preguntas_frecuentes', [item.page]),
          state: pageContext.name === item.page ? 'isActive' : 'default',
          color: 'hotel',
        }))
        .filter((_item) => _item !== undefined);

      return {
        isSquare: {
          title: this.props.data.allFqHeaderTitleBlock.edges[0].node.description,
        },
        tabs: hotelTabs,
      };
    };

    const prepareForAnnualPass = (type) => {
      return {
        pases: {
          title: this.props.data.allFqPreguntasFrecuentesTitle.edges[0].node.title,
          text: '',
          menus: this.props.data.pasesFAQ.edges.map((item) => ({
            title: item.node.question,
            text: item.node.answer,
          })),
          titleType: 'h3',
        },
        areaPrivada: {
          title: this.props.data.allFqPreguntasFrecuentesTitle.edges[1].node.title,
          text: '',
          menus: this.props.data.areaPrivadaFAQ.edges.map((item) => ({
            title: item.node.question,
            text: item.node.answer,
          })),
          titleType: 'h3',
        },
        pago: {
          title: this.props.data.allFqPreguntasFrecuentesTitle.edges[2].node.title,
          text: '',
          menus: this.props.data.pagoFAQ.edges.map((item) => ({
            title: item.node.question,
            text: item.node.answer,
          })),
          titleType: 'h3',
        },
        klarna: {
          title: this.props.data.allFqPreguntasFrecuentesTitle.edges[3].node.title,
          text: '',
          menus: this.props.data.klarnaFAQ.edges.map((item) => ({
            title: item.node.question,
            text: item.node.answer,
          })),
          titleType: 'h3',
        },
      }[type];
    };

    return (
      <Layout
        layoutFromOld
        locale={this.props.pageContext.locale}
        pageName={this.props.pageContext.pageName}
        options={this.props.pageContext.options}
      >
        <SEO
          title={this.props.data.allFqSeoData.edges[0].node._0.title}
          description={this.props.data.allFqSeoData.edges[0].node._1.description}
          pathname={this.props.pageContext.url}
          lang={this.props.pageContext.locale}
        />
        <Hod
          data={this.props.data.allFqRichData.edges[0].node.rich_markup}
          url={this.props.pageContext.url}
          img={this.props.data.allFqImageHeaderBlock.edges[0].node.image}
        />
        <div className="is-fullWidth">
          <Heimdall data={heimdallData} />
          <div className="general-index">
            <div className="fq-content">
              <Forseti data={prepareForsetiData()} />
              <NeoAesir
                locale={this.props.pageContext.locale}
                pageName={this.props.pageContext.pageName}
                options={this.props.pageContext.options}
                title={this.props.data.allFqBreadCrumbBlock.edges[0].node.name}
              />

              {this.props.pageContext.name !== 'pases-anuales' ? (
                <Hellheim data={hellheimData} />
              ) : (
                <>
                  <Hellheim data={prepareForAnnualPass('pases')} />
                  <Hellheim data={prepareForAnnualPass('areaPrivada')} />
                  <Hellheim data={prepareForAnnualPass('pago')} />
                  <Hellheim data={prepareForAnnualPass('klarna')} />
                </>
              )}
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default PreguntasFrecuentes;
