import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { MediaMatcher, MediaServerRender } from 'react-media-match';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import { injectIntl } from 'react-intl';
import Icon from '../../helpers/icon';
import './forseti.scss';
import logo_ap from '../../images/entradas-logo-large-cap.png';
import logo_fl from '../../images/entradas-logo-large-fl.png';
import logo_pa from '../../images/entradas-logo-large-pap.png';
import forsetiIcons from './forsetiIcons';
import Pawlink from '../PawLink/PawLink';
import tt from '../../helpers/translation';

class Forseti extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: null,
      openmenu1: false,
      openmenu2: false,
      openmenu3: false,
      tabTitle: null,
      showBox: false,
      openFilter: false,
      activeTab: null,
    };
  }

  componentDidMount() {
    const { data } = this.props;
    const activeTab = data.tabs.filter(
      (item) => item.state === 'isActive' && item.color !== 'schedule'
    );
    this.setState({ activeTab: activeTab[0] });
  }

  handleClick = (key) => () => {
    const { state } = this;
    const obj = {};
    state[key] = !state[key];
    this.setState(obj);
    this.setState({ openFilter: !state.openFilter });
  };

  toggleBox = () => {
    const { state } = this;
    this.setState({ showBox: !state.showBox });
  };

  render() {
    const {
      state: { openmenu1, activeTab, openFilter },
      props: {
        data: { tabs, isSquare },
        intl: { locale },
      },
    } = this;

    const logoArr = [
      {
        img: logo_pa,
      },
      {
        img: logo_ap,
      },
      {
        img: logo_fl,
      },
      {
        img: '',
      },
      {
        img: '',
      },
      {
        img: '',
      },
      {
        img: '',
      },
      {
        img: '',
      },
    ];

    const forsetiMobile = (
      <div className="forseti-uppertab-container">
        <div className={`tab-menu ${activeTab && activeTab.color}`}>
          <ListItem
            button
            className={`firstlevelpasesmenu  ${!openFilter ? '' : 'open'}`}
            onClick={this.handleClick('openmenu1')}
          >
            <ListItemText
              className={`firstlevelpasesmenu-text ${activeTab && activeTab.color}`}
              inset
              primary={`${this.state.activeTab && this.state.activeTab.name}`}
            />
            {openmenu1 ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={openmenu1} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {tabs.map((tab, index, arr) => (
                <ListItem
                  button
                  className={
                    index === arr.length - 1
                      ? `secondpaseslevelmenu ${tab.color} last`
                      : `secondpaseslevelmenu ${tab.color}`
                  }
                  value={tab.name}
                >
                  <ListItemText
                    className={`secondpaseslevelmenu-text ${tab.color}`}
                    inset
                    primary={
                      <Link className={`${tab.color}`} to={tab.link}>
                        {tab.name}
                      </Link>
                    }
                  />
                </ListItem>
              ))}
            </List>
          </Collapse>
        </div>
        <div className="forseti-rectangle">
          <div className="text-container">
            <h2
              className={`is-square-title ${this.state.activeTab && this.state.activeTab.color}`}
              dangerouslySetInnerHTML={{ __html: isSquare.title }}
            />
            <span className="is-square-text" dangerouslySetInnerHTML={{ __html: isSquare.text }} />
            <div
              className={`second-text-hidden ${!this.state.showBox ? '' : 'nohidden'}`}
              dangerouslySetInnerHTML={{ __html: isSquare.description }}
            />
            {isSquare.description === ('' || undefined) ? (
              ''
            ) : (
              <div className="seemore" onClick={this.toggleBox}>{`${
                !this.state.showBox ? tt('Leer más', locale) : tt('Leer menos', locale)
              }`}</div>
            )}
          </div>
        </div>
      </div>
    );

    let forsetiMode;

    if (isSquare) {
      forsetiMode = (
        <div className="forseti-uppertab-container">
          <div className="forseti-tab-wrapper">
            {tabs.map((tab, index) => (
              <div className={`tab ${tab.color} ${tab.state}`}>
                {isSquare.logo ? (
                  <div className="logo-container">
                    <img src={logoArr[index].img} />{' '}
                  </div>
                ) : (
                  ''
                )}
                {logoArr[index].img === '' ? (
                  <Icon
                    icon="chevron-left"
                    color="white"
                    width="15"
                    height="15"
                    color="#5E5E5E"
                    iconSet={forsetiIcons}
                  />
                ) : (
                  ''
                )}
                <Link className={`${tab.color}`} to={tab.link}>
                  {tab.name}
                </Link>
              </div>
            ))}
          </div>
          <div className="forseti-rectangle">
            <div className="text-container">
              <h2
                className={`is-square-title ${this.state.activeTab && this.state.activeTab.color}`}
                dangerouslySetInnerHTML={{ __html: isSquare.title }}
              />
              <span className="is-square-text">{isSquare.text}</span>
              <div
                className={`second-text-hidden ${!this.state.showBox ? '' : 'nohidden'}`}
                dangerouslySetInnerHTML={{ __html: isSquare.description }}
              />
              {isSquare.description === undefined ? (
                ''
              ) : (
                <div className="seemore" onClick={this.toggleBox}>{`${
                  !this.state.showBox ? tt('Leer más', locale) : tt('Leer menos', locale)
                }`}</div>
              )}
            </div>
          </div>
        </div>
      );
    } else {
      forsetiMode = (
        <div className="forseti-tab-container">
          {tabs.map((tab) => (
            <div className={`tab ${tab.color} ${tab.state}`}>
              <Link class="unknown" to={tab.link} className={`${tab.color}`}>
                {tab.name}
              </Link>
            </div>
          ))}
        </div>
      );
    }

    return (
      <div className="forseti-container">
        <MediaServerRender predicted="desktop" hydrated>
          <MediaMatcher desktop={forsetiMode} mobile={forsetiMobile} />
        </MediaServerRender>
      </div>
    );
  }
}

Forseti.propTypes = {
  data: PropTypes.object.isRequired,
};

export default injectIntl(Forseti);
